import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Modal from "react-modal";
import Axios from "../utils/axios";

import ReactPaginate from "react-paginate";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { DashboardContext, SET_ACTIVE_FEED } from "../context/dashboard";
import { CSVLink, CSVDownload } from "react-csv";
import { showToast } from "utils/general";
import { Link } from "react-router-dom";
import CsvDownloader from "react-csv-downloader";

Modal.setAppElement("#root");
function Users() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(0);
  const [users, setUsers] = useState({});
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(50);
  const [keyword, setKeyword] = useState("");
  const [searching, setSearching] = useState(false);
  const [csvResults, setCsvResults] = useState([]);

  const context = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);

  const { activeFeed } = dashboardContext.dashboardState || {
    activeFeed: {},
  };

  useEffect(() => {
    // empty active user
    if (
      // Helper.isPopulator(context.authState?.admin) ||
      Helper.isAdministrator(context.authState?.admin)
    ) {
      return false;
    }

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [limit]);

  useEffect(() => {
    prepareForCSV();
  }, [results]);

  const csvHeader = [
    { label: "Customet ID", key: "Customer_id" },
    { label: "Name", key: "Name" },
    { label: "Gender", key: "Gender" },
    { label: "Phone", key: "Phone" },
    { label: "City", key: "City" },
    { label: "Wallet", key: "Wallet" },
    { label: "Accion Wallet", key: "Accion_wallet" },
    { label: "Referred By", key: "Referred_by" },
    { label: "Referred By Email", key: "Referred_By_Email" },
    { label: "Referral Code", key: "ReferralCode" },
    { label: "Created At", key: "Created_At" },
    { label: "Updated At", key: "Updated_At" },
  ];

  const prepareForCSV = async () => {
    let newResults = [];

    results.forEach(async (result) => {
      newResults.push({
        Customer_id: result?._id,
        Name: result?.name ?? result?.firstname + " " + result?.lastname,
        Gender: result?.gender ?? "",
        Phone: result?.phone ?? "",
        Email: result?.email ?? "",
        City: result?.city ?? "",
        Wallet: result?.wallet ?? 0,
        Accion_wallet: result?.accion_wallet ?? 0,
        Referred_by: result?.referredBy?.name ?? "",
        Referred_By_Email: result?.referredBy?.email ?? "",
        ReferralCode: result?.referralCode ?? "",
        Created_At: result?.createdAt ?? "",
        Updated_At: result?.updatedAt ?? "",
      });
    });
    
    setCsvResults(newResults);
  };

  const fetchData = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/users?limit=${limit}&page=${offSet}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setUsers(usersResponse?.data || []);
        setResults(usersResponse?.data?.results || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch users`);
      setLoading(false);
    } finally {
      setSearching(false);
    }
  };
  const toggleUserStatus = async (status, userId) => {
    const formData = {
      status: status ? "active" : "inactive",
      userId,
    };

    try {
      const { token } = context.authState;

      setSubmitLoading(userId);
      let response = await Axios.put(
        `${process.env.REACT_APP_API_V2}/admin/users/toggle`,
        formData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      if (data && data?._id) {
        const index = results
          ? results?.findIndex((p) => p?._id == data?._id)
          : -1;
        if (index > -1) {
          const updatedUsers = results;
          const updated = {
            ...updatedUsers[index],
            userId: data?.userId,
            status: data?.status,
          };
          updatedUsers[index] = updated;
          setResults([...updatedUsers]);
        }
      } else {
        showToast(`Oops. Failed to save item`);
      }
      setSubmitLoading(0);
    } catch (error) {
      showToast(`Oops. something went wrong while saving item`);

      setSubmitLoading(0);
    }
  };

  const searchUsers = async () => {
    try {
      const { token } = context.authState;

      setLoading(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_API_V2}/admin/users/search/${keyword}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;
      setUsers(data || []);
      setResults(data || []);
      setSearching(true);
      setKeyword("");

      setLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while searching user`);
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;

    fetchData(selected);
  };

  if (
    // Helper.isPopulator(context.authState?.admin) ||
    Helper.isAdministrator(context.authState?.admin)
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <span style={{ fontSize: 10 }}>Showing: </span>
                        <Row style={{ marginLeft: 2 }}>
                          <select onChange={(e) => setLimit(e.target.value)}>
                            <option>50</option>
                            <option>100</option>
                            <option>1000</option>
                            <option>5000</option>
                          </select>
                          <div style={{ marginLeft: 2 }}></div>
                          <Button className="btn-sm">
                            <CSVLink
                              headers={csvHeader}
                              data={csvResults}
                              className="card-category"
                              filename="Justrite Users"
                            >
                              Export CSV
                            </CSVLink>
                          </Button>
                        </Row>
                        {/* <Card.Title as="h4">All users</Card.Title> */}
                        {/* <p className="card-category">Backend development</p> */}
                        {/* <Button className="btn-sm">
                          <CSVLink data={results} className="card-category">
                            Export CSV
                          </CSVLink>
                        </Button> */}
                      </Col>
                      {!!searching && (
                        <Button className="btn-sm" onClick={fetchData}>
                          See all
                        </Button>
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Form
                      className="container"
                      noValidate
                      // validated={searchValidated}
                      onSubmit={(e) => {
                        e.preventDefault();
                        searchUsers();
                      }}
                    >
                      <Row>
                        <Col md="10">
                          <Form.Group>
                            {/* <label>Keyword</label> */}
                            <Form.Control
                              placeholder="Enter Search Keyword"
                              type="text"
                              required
                              onChange={(e) => setKeyword(e.target.value)}
                              value={keyword}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a keyword.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            {/* <label>Search</label> */}
                            <Button
                              className="btn-fill form-control"
                              type="submit"
                              variant="primary"
                              style={{
                                background: CustomStyle.colors.primary,
                                borderColor: CustomStyle.colors.primary,
                              }}
                            >
                              <i className="nc-icon nc-zoom-split"></i>
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {results && results?.length > 0 ? (
                          results?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div>
                                    <span>Name:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      <Link to={"finance/" + item?._id}>
                                        {item?.name}
                                      </Link>
                                    </b>
                                  </div>
                                  <div>
                                    <span>Email Address:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.email}
                                    </b>
                                  </div>
                                  <div>
                                    <span>Phone Number:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.phone}
                                    </b>
                                  </div>
                                  <small>
                                    Created {moment(item?.createdAt).fromNow()}{" "}
                                    ({item?.createdAt})
                                  </small>
                                </td>
                                <td className="td-actions text-right">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Toggle user status
                                      </Tooltip>
                                    }
                                  >
                                    {item?.status == "active" ? (
                                      <Button
                                        className="btn btn-danger btn-fill"
                                        type="button"
                                        disabled={submitLoading == item?._id}
                                        variant="danger"
                                        onClick={async () =>
                                          toggleUserStatus(false, item?._id)
                                        }
                                      >
                                        {submitLoading == item?._id
                                          ? "Deactivating"
                                          : "Deactivate"}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="btn btn-info btn-fill"
                                        type="button"
                                        variant="info"
                                        disabled={submitLoading == item?._id}
                                        onClick={async () =>
                                          toggleUserStatus(true, item?._id)
                                        }
                                      >
                                        {submitLoading == item?._id
                                          ? "Activating"
                                          : "Activate"}
                                      </Button>
                                    )}
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No user found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {users && users?.total ? (
                  <ReactPaginate
                    previousLabel={<i className="nc-icon nc-stre-left"></i>}
                    nextLabel={<i className="nc-icon nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={users?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Users;
